<template>
  <div>
    <StandardModal
      :showModal="showModal"
      :closeModal="closeModal"
      :buttonTappedAction="confirmIdCheck"
      :title="idModalTitle"
      :buttonTitle="idModalbuttonText"
      >
      <div class="verification-modal-text">{{idModalText}}</div>
    </StandardModal>
  </div>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import { doAccountVerification } from '@utils/common-methods/profileHelper'

export default {
  components: {
    StandardModal,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false
    },
    closeModal: {
      type: Function,
      required: true
    },
    governmentIdStatus: {
      required: true,
      default: ''
    },
    investorOnVeriffWatchlist: {
      type: Boolean,
      required: true,
      default: false
    },
    useVeriff: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    idModalTitle() {
      if (this.investorOnVeriffWatchlist) return 'Account verification'
      else return 'Verify account first 👌'
    },
    idModalbuttonText() {
      if (this.governmentIdStatus === 'pending' || this.investorOnVeriffWatchlist) return 'Got it'
      else return 'Verify account'
    },
    idModalText() {
      if (this.governmentIdStatus === 'pending') {
        return `Your government ID hasn't been approved yet— but once it has, you’ll be able to continue your investment. Thanks for your patience! 🙏`
      } else if (this.investorOnVeriffWatchlist) {
        return 'We apologize, we are unable to complete this request for you at this time.'
      } else if (this.governmentIdStatus === 'failed') {
        return 'There was an issue with the previous government ID that you provided. 😥 Please verify your indentity again in order to continue your investment. Sorry for the inconvenience!'
      } else {
        return `Your identity hasn't been verified yet. Please verify your account in order to continue your investment.`
      }
    },
  },
  methods: {
    confirmIdCheck() {
      if (this.governmentIdStatus === 'pending' || this.investorOnVeriffWatchlist) this.closeModal()
      else doAccountVerification(this.useVeriff, this, this.$route.fullPath)
    },
  },
}
</script>
<style lang="scss" scoped>
.verification-modal-text {
  font-size: 16px;
  line-height: 19px;
}
</style>
