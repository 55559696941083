const InvestmentTypes = {
  'Value-Add': {
    riskProfile: 'Moderate to high',
    investorType: 'For investors willing to take on more risk to achieve high returns and are willing to wait longer before they begin receiving returns for their investment.',
    riskLevel: 'A Value-Add investment often has little to no cash flow at acquisition but has the potential to produce a tremendous amount of cash flow once the value has been added (for example physical upgrades, better management, added services or more effective marketing).',
  },
  'Opportunistic': {
    riskProfile: 'High',
    investorType: 'For investors willing to take on the most complicated projects and may not see a return on their investment for three or more years.',
    riskLevel: 'An Opportunistic investment often has little to no cash flow at acquisition but has the potential to produce a tremendous amount of cash flow once the value has been added. Examples include developments, acquiring an empty building, land development and repositioning a building from one use to another.',
  },
  'Core': {
    riskProfile: 'Conservative',
    investorType: 'For investors looking to generate stable income with very low risk.',
    riskLevel: 'A Core investment usually requires very little management from owners and are usually occupied with tenants on long-term leases. Core investments are typically acquired and held. This type of investing is as close as one can get to passive investing when buying properties directly.',
  },
  'Core Plus': {
    riskProfile: 'Low to moderate',
    investorType: 'For investors looking for little risk with some upside.',
    riskLevel: 'A Core Plus investment can typically increase cash flows through light property improvements, management efficiencies or by increasing the quality of the tenants. Similar to core properties, these properties tend to be of high-quality and well-occupied.',
  },
}

export default InvestmentTypes
