<template>
  <div :class="`property-purchase ${$isMobile ? 'is-mobile' : ''}`">
    <div v-if="$isMobile" class="fixed-header is-flex is-justify-content-center is-align-items-center has-background-white">
      <img src="@assets/icons/arrow-left.svg" alt="go back" class="nav-back" @click="navBack">
      <img src="@assets/images/common/logo.png" alt="logo" class="logo" @click="toDashboard">
      <div :class="`referral-icon is-flex is-justify-content-center is-align-items-center ${seenReferralIcon ? '' : 'bouncing'}`" @click="toReferralDashboard">
        <img src="@assets/icons/gift.svg" alt="referral icon">
        <div
          class="notice-icon is-flex is-justify-content-center is-align-items-center has-text-white"
          @click.stop="()=>{return false}"
          v-if="!seenReferralIcon || hasNewReferrals"
        >!</div>
      </div>
    </div>
    <NavBack/>
    <div class="main-body">
      <div class="nickname has-text-weight-semibold has-text-centered">{{nickname}}</div>
      <div class="purple-box has-text-centered">
        <div class="status">{{status}}</div>
        <img :src="miniPropUrl" alt="mini prop" class="mini-prop">
        <div class="buy-widget">
          <template v-if="saleStatus === 'sale'">
            <template v-if="isTimeBased">
              <div class="dynamic-text has-mt-32 has-mb-8">Potentially
                <span class="has-text-weight-semibold bold">{{getTimeBasedText(purchasedRate)}}</span>
                remaining ⏱
              </div>
            </template>
            <template v-else>
              <div class="dynamic-text has-mt-32 has-mb-8" v-if="remainingForSale">
                <span class="has-text-weight-semibold bold">${{listPrice - purchased | formatNumber}}</span> remaining of ${{listPrice | formatNumber}} 💰
              </div>
              <div class="dynamic-text has-mt-32 has-mb-8" v-else>
                <span class="has-text-weight-semibold bold">${{purchased | formatNumber}}</span> of ${{listPrice | formatNumber}} sold 💰
              </div>
            </template>
          </template>
          <template v-else-if="saleStatus === 'pre_sale'">
            <div class="dynamic-text has-mt-18">{{preOfferingHeader}}: ${{listPrice | formatNumber}}</div>
            <div class="dynamic-text has-mb-8">Come back soon for the drop 😎</div>
          </template>
          <template v-else>
            <div class="dynamic-text has-mt-32 has-mb-8">
              ${{listPrice | formatNumber}} of ${{listPrice | formatNumber}} sold ⏱
            </div>
          </template>
          <div class="progress-bar">
            <div class="inner-bar has-background-blue" :style="`width: ${purchasedRate}%;`" v-if="saleStatus === 'sale'"></div>
            <div class="inner-bar has-background-blue" :style="`width: 100%;`" v-else-if="saleStatus === 'post_sale'"></div>
          </div>
        </div>
        <div
          v-if="hasEnglishOM"
          class="om-button has-background-white is-flex is-justify-content-center is-align-items-center has-text-blue has-text-weight-bold is-clickable"
          @click="viewOM(purchaseProcessType === 'crowdfunding' ? 'crowd_funding_document' : 'offering_memorandum_document')"
        >
          <img src="@assets/icons/file.svg" alt="Offering Memorandum">
          Read the {{isAmerican ? 'OC' : (purchaseProcessType === 'crowdfunding' ? 'Offering Document' : 'OM')}}
        </div>
        <div
          v-if="hasFrenchOM"
          class="om-button french-om has-background-white is-flex is-justify-content-center is-align-items-center has-text-blue has-text-weight-bold is-clickable mt-3"
          @click="viewOM('french_offering_memorandum_document')"
        >
          <img src="@assets/icons/file.svg" alt="French Offering Memorandum">
          Lire la notice d'offre
        </div>
        <div class="is-flex is-justify-content-space-between metrics mt-5" v-if="investorCount && averageInvestment && saleStatus !== 'pre_sale'">
          <div class="irr has-background-white is-flex is-flex-direction-column is-align-items-center">
            <div class="box-title has-text-weight-semibold">Number of Investors</div>
            <div class="has-text-blue">{{investorCount}}</div>
          </div>
          <div class="roi has-background-white is-flex is-flex-direction-column is-align-items-center">
            <div class="box-title has-text-weight-semibold">Average Investment</div>
            <div class="has-text-blue is-relative currency-container">
              <span class="symbol has-text-weight-semibold has-text-blue">$</span>
              {{averageInvestment | formatNumber2Decimals}}
              <span class="currency has-text-blue has-text-weight-bold">{{country === 'Canada' ? 'CAD' : 'USD'}}</span>
            </div>
          </div>
        </div>
        <div class="term has-background-white is-flex is-flex-direction-column is-align-items-center" v-if="term">
          <div class="box-title has-text-weight-semibold">Estimated Term</div>
          <div class="has-text-blue">{{term}} Year{{term > 1 ? 's' : ''}}</div>
        </div>
        <div class="distribution has-background-white is-flex is-flex-direction-column is-align-items-center" v-if="estimatedOwnersDays">
          <div class="box-title has-text-weight-semibold">Estimated Owners' Days</div>
          <div class="has-text-blue">{{estimatedOwnersDays}}</div>
        </div>
        <div class="is-flex is-justify-content-space-between metrics" v-if="estimatedIrr && estimatedRoi">
          <div class="irr has-background-white is-flex is-flex-direction-column is-align-items-center">
            <div class="box-title has-text-weight-semibold">Estimated IRR</div>
            <div class="has-text-blue">{{estimatedIrr}}
              <span class="symbol has-text-weight-semibold has-text-blue">%</span>
            </div>
          </div>
          <div class="roi has-background-white is-flex is-flex-direction-column is-align-items-center">
            <div class="box-title has-text-weight-semibold">Estimated ROI</div>
            <div class="has-text-blue">{{estimatedRoi}}
              <span class="symbol has-text-weight-semibold has-text-blue">%</span>
            </div>
          </div>
        </div>
        <template v-if="saleStatus === 'exited'">
          <div class="term has-background-white is-flex is-flex-direction-column is-align-items-center">
            <div class="box-title has-text-weight-semibold">Actual Term</div>
            <div class="has-text-blue">{{exitedTerm}} Year{{exitedTerm > 1 ? 's' : ''}}</div>
          </div>
          <div class="distribution has-background-white is-flex is-flex-direction-column is-align-items-center">
            <div class="box-title has-text-weight-semibold">Actual Owners' Days</div>
            <div class="has-text-blue">{{completedOwnersDays}}</div>
          </div>
          <div class="is-flex is-justify-content-space-between metrics">
            <div class="irr has-background-white is-flex is-flex-direction-column is-align-items-center">
              <div class="box-title has-text-weight-semibold">Actual IRR</div>
              <div class="has-text-blue">{{exitedIrr}}
                <span class="symbol has-text-weight-semibold has-text-blue">%</span>
              </div>
            </div>
            <div class="roi has-background-white is-flex is-flex-direction-column is-align-items-center">
              <div class="box-title has-text-weight-semibold">Actual ROI</div>
              <div class="has-text-blue">{{exitedRoi}}
                <span class="symbol has-text-weight-semibold has-text-blue">%</span>
              </div>
            </div>
          </div>
        </template>
        <div class="is-flex is-justify-content-space-between scores" v-if="walkScore && transitScore">
          <div class="walk-score has-background-white is-flex is-flex-direction-column is-align-items-center">
            <a :href="walkScoreURL" target="_blank" class="box-title has-text-weight-semibold">
              Walk Score®
            </a>
            <a :href="walkScoreURL" target="_blank" class="has-text-blue">
              {{walkScore}}
            </a>
          </div>
          <div class="transit-score has-background-white is-flex is-flex-direction-column is-align-items-center">
            <a :href="walkScoreURL" target="_blank" class="box-title has-text-weight-semibold">
              Transit Score®
            </a>
            <a :href="walkScoreURL" target="_blank" class="has-text-blue">
              {{transitScore}}
            </a>
          </div>
        </div>
      </div>
      <div class="more-info">
        <div class="name has-text-weight-semibold">{{name}}</div>
        <div class="description">{{shortDescription}}</div>
        <div class="plan" v-if="plan">The Plan: {{plan}}</div>
        <section class="gp has-text-centered" v-if="generalPartnerType">
          <div class="section-title has-text-weight-semibold">General Partner Type</div>
          <div class="gp-type-icon is-inline-flex is-justify-content-space-between is-align-items-center">
            <img :src="gpIconUrl" alt="coming up">
            <span class="has-text-blue has-text-weight-semibold">{{generalPartnerType}}</span>
          </div>
          <div class="has-text-left">{{generalPartnerInfo}}</div>
        </section>
        <section class="investment-type has-text-centered" v-if="typeOfInvestment">
          <div class="section-title has-text-weight-semibold">Opportunity Type</div>
          <div class="type-of-investment is-inline-flex is-justify-content-space-between is-align-items-center">
            <img :src="typeIconUrl" alt="type of investment">
            <span class="has-text-blue has-text-weight-semibold type">{{typeOfInvestment}}</span>
          </div>
          <div class="types-info has-text-left">
            <!-- risk profile temporarily removed for Castleview (5j81hvo) -->
            <template v-if="propertyId !== '5j81hvo'">
              <div class="has-text-blue has-text-weight-semibold">Risk Profile:</div>
              <div class="mb-3">{{riskProfile}}</div>
            </template>
            <div class="has-text-blue has-text-weight-semibold">Investor Type:</div>
            <div class="mb-3">{{investorType}}</div>
            <div class="has-text-blue has-text-weight-semibold">What defines this risk level?</div>
            <div>{{riskLevel}}</div>
          </div>
        </section>
        <section class="highlights" v-if="highlights && highlights.length">
          <div class="section-title has-text-weight-semibold has-text-centered">Property Highlights</div>
          <div class="highlights-item is-flex" v-for="(item, index) in highlights" :key="index">
            <div class="dot"></div>
            <div class="text">{{item}}</div>
          </div>
        </section>
        <section class="due-diligence-section">
          <div class="due-diligence" v-if="disclosedFiles.length">
            <div class="doc-section-title has-text-weight-semibold">Due Diligence</div>
            <div
              v-for="(file, index) in disclosedFiles"
              :key="index"
              class="file-wrap is-flex is-justify-content-space-between is-align-items-center"
              @click="viewFile(file.modelName, file.id, file.docType)">
              <img src="@assets/icons/file.svg" alt="file" class="doc-icon">
              <div class="doc-info has-text-left is-flex is-flex-direction-column is-justify-content-center">
                <div class="name">{{file.filename}}</div>
                <div class="doc-size">{{file.size}}
                  <span class="is-uppercase">{{file.extension}}</span> file
                </div>
              </div>
              <img src="@assets/icons/view.svg" alt="view" class="view-icon">
            </div>
          </div>
          <div class="confidential-due-diligence" v-if="confidentialFiles.length">
            <div class="is-flex is-justify-content-space-between">
              <div class="doc-section-title has-text-weight-semibold">Confidential Due Diligence</div>
              <img src="@assets/icons/unlock.svg" alt="unlock" v-if="confidentialDocSigned" class="title-icon">
              <img src="@assets/icons/lock.svg" alt="lock" v-else class="title-icon">
            </div>
            <div class="sign-confidential-doc" v-if="!confidentialDocSigned">
              <button class="button is-blue is-fullwidth" @click="signConfidentialityAgreement">
                <img src="@assets/icons/file-white.svg" alt="file">
                <span class="has-text-white has-text-weight-bold">Confidentiality Agreement</span>
              </button>
              <div class="sign-hint has-text-lavender has-text-centered">To view confidential due diligence documents, you must sign our Confidentiality Agreement ✍</div>
            </div>
            <div
              v-for="(file, index) in confidentialFiles"
              :key="index"
              class="file-wrap is-flex is-justify-content-space-between is-align-items-center"
              @click="viewConfidentialFile(file.modelName, file.id, file.docType)">
              <img src="@assets/icons/file.svg" alt="file" class="doc-icon">
              <div class="doc-info has-text-left is-flex is-flex-direction-column is-justify-content-center">
                <div class="name">{{file.filename}}</div>
                <div class="doc-size">{{file.size}}
                  <span class="is-uppercase">{{file.extension}}</span> file
                </div>
              </div>
              <img src="@assets/icons/view.svg" alt="view" class="view-icon" v-if="confidentialDocSigned">
              <img src="@assets/icons/lock.svg" alt="lock" class="view-icon" v-else>
            </div>
          </div>
        </section>
        <section class="disclaimer-section has-text-left">
          <Footer
            :hasLinks="false"
            :hasAtlasOneDisclaimer="true"
          ></Footer>
        </section>
      </div>
    </div>
    <div class="fixed-button is-flex is-justify-content-center">
      <button
        class="button is-primary is-rounded is-flex is-align-items-center"
        @click="goNext"
        v-if="saleStatus === 'sale'"
      >
        INVEST NOW <span class="ml-2">+</span>
      </button>
      <button v-else class="button is-primary is-rounded is-flex is-align-items-center" disabled>{{disabledButtonText}}</button>
    </div>
    <DisclaimerModal
      @closeDisclaimerModal="closeDisclaimerModal"
      type="atlasOneInvest"
      v-if="showDisclaimer"
    ></DisclaimerModal>
    <IdVerificationModal
      :showModal="showIdVerificationModal"
      :closeModal="() => { showIdVerificationModal = false }"
      :governmentIdStatus="governmentIdStatus"
      :investorOnVeriffWatchlist="investorOnVeriffWatchlist"
      :useVeriff="useVeriff"
    >
    </IdVerificationModal>
    <EarlyAccessModal
      title="Early Access Only"
      text="Sorry, investment is open for paid members only for the first 3 days. Please come back later."
      :showModal="showEarlyAccessModal"
      continueButtonText="Buy membership"
      @cancelAction="showEarlyAccessModal = false"
      @doContinue="toMembershipSelection"
    ></EarlyAccessModal>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import DisclaimerModal from '@components/modal/disclaimer-modal.vue'
import Footer from '@views/referral/landing/Footer.vue'
import IdVerificationModal from '@components/modal/id-verification-modal.vue'
import EarlyAccessModal from '@components/modal/continue-or-cancel-modal.vue'
import { updateInvestorFlags, fetchDocUrl, getFeatureToggle } from '@api/common'
import { getReferralFlags } from '@api/dashboard'
import { getPropertyDetails, verifyRegion } from '@api/properties'
import { getWealthInfo } from '@api/profile'
import { DateTime } from 'luxon'
import { formatter } from '@utils/common-methods/numberFormatter'
import { isAmericanUser } from '@utils/common-methods/common'
import GeneralPartnerTypes from '@utils/data/general_partner_types'
import InvestmentTypes from '@utils/data/investment_types'
import { mapState } from 'vuex'
import { disclaimerParagraphs, statements } from '@utils/data/statements.js'

const walkScoreLink = 'https://www.walkscore.com/how-it-works/'

export default {
  components: {
    NavBack,
    DisclaimerModal,
    Footer,
    IdVerificationModal,
    EarlyAccessModal,
  },
  data() {
    return {
      seenReferralIcon: true,
      hasNewReferrals: false,
      saleStatus: '',
      nickname: '',
      miniPropUrl: '',
      isTimeBased: false,
      purchasedRate: '',
      remainingForSale: false,
      listPrice: 0,
      purchased: 0,
      preOfferingHeader: 'List Price',
      estimatedOwnersDays: 0,
      corporationId: undefined,
      term: 0,
      estimatedIrr: '',
      estimatedRoi: '',
      walkScore: '',
      transitScore: '',
      name: '',
      shortDescription: '',
      plan: '',
      generalPartnerType: '',
      typeIconUrl: '',
      gpIconUrl: '',
      typeOfInvestment: '',
      highlights: [],
      country: '',
      membershipPaid: false,
      membershipType: '',
      accreditedInvestorStatus: '',
      confidentialDocSigned: false,
      confidentialFiles: [],
      disclosedFiles: [],
      investorInAvailableRegions: true,
      hasFrenchOM: false,
      hasEnglishOM: false,
      purchaseProcessType: '',
      investorCount: 0,
      averageInvestment: 0,
      exitedIrr: '',
      exitedRoi: '',
      exitedTerm: 0,
      completedOwnersDays: 0,
      annualIncome: 0,
      netWorth: 0,
      acknowledgedInvestmentDisclaimer: false,
      showDisclaimer: false,
      governmentIdStatus: '',
      showIdVerificationModal: false,
      useVeriff: true,
      disclaimerParagraphs: Object.freeze(disclaimerParagraphs),
      statements: Object.freeze(statements),
      investorOnVeriffWatchlist: false,
      nonMemberWaitPeriodActive: false,
      showEarlyAccessModal: false,
      walkScoreURL: walkScoreLink,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    status() {
      if (!this.saleStatus) return ''
      const statusText = {
        pre_sale: 'Dropping soon',
        sale: 'Available to invest',
        post_sale: 'Sold out',
        reconciliation: 'Sold out',
        settled: 'Sold out',
        exited: 'Exited',
      }
      return statusText[this.saleStatus]
    },
    disabledButtonText() {
      if (!this.saleStatus) return ''

      if (this.saleStatus === 'pre_sale') return 'COMING SOON'
      else if (this.saleStatus === 'exited') return 'EXITED'
      else return 'SOLD OUT'
    },
    propertyId() {
      return this.$route.params.propertyId
    },
    generalPartnerInfo() {
      return GeneralPartnerTypes[this.generalPartnerType] || ''
    },
    riskProfile() {
      return this.typeOfInvestment ? InvestmentTypes[this.typeOfInvestment].riskProfile : ''
    },
    investorType() {
      // Castleview (5j81hvo)
      if (this.propertyId === '5j81hvo') return 'For investors looking to generate stable income, with potential capital gains, but with minimal or limited development risk.'
      return this.typeOfInvestment ? InvestmentTypes[this.typeOfInvestment].investorType : ''
    },
    riskLevel() {
      // Castleview (5j81hvo)
      if (this.propertyId === '5j81hvo') return InvestmentTypes.Core.riskLevel
      return this.typeOfInvestment ? InvestmentTypes[this.typeOfInvestment].riskLevel : ''
    },
    nextStepUrl() {
      const nameSpaceUrl = this.$route.params.nameSpaceUrl
      if (this.isAmerican) return this.requiresIncomeReporting ? `/${nameSpaceUrl}/buy/${this.propertyId}/incomeReporting` : `/${nameSpaceUrl}/buy/amount/${this.propertyId}`
      return `/investorProfile/suitabilityQuestionnaire?propertyId=${this.propertyId}&distributor=${nameSpaceUrl}`
    },
    isAmerican() {
      return isAmericanUser()
    },
    requiresIncomeReporting() {
      return this.membershipType !== 'accredited_membership' && (!this.netWorth || !this.annualIncome)
    },
  },
  filters: {
    formatNumber(value) {
      return formatter(value, 0)
    },
    formatNumber2Decimals(value) {
      return formatter(value, 2)
    },
  },
  created() {
    this.clearAvailableCountryForInvestment()
    this.fetchReferralStatus()
    this.fetchData()
    this.checkAvailableRegions()
    this.getWealthDataOfAmerican()
    this.checkIdVerificationFlow()
  },
  methods: {
    clearAvailableCountryForInvestment() {
      const country = localStorage.getItem('availableCountryForInvestment')
      country && localStorage.removeItem('availableCountryForInvestment')
    },
    fetchReferralStatus() {
      getReferralFlags().then((data) => {
        if (!data.success) return
        this.seenReferralIcon = data.data.referralGiftIconSeenAt
        this.hasNewReferrals = data.data.newReferrals
      })
    },
    fetchData() {
      getPropertyDetails(this.propertyId).then((result) => {
        if (!result.success) return
        Object.assign(this, result.data.property)
        // for the disclaimer modal on the questionaire page
        const disclaimer = result.data.property.dealerDisclaimer
        disclaimer && localStorage.setItem('dealer_disclaimer', disclaimer)

        this.typeIconUrl = this.getTypeIconUrl(this.typeOfInvestment)
        this.gpIconUrl = this.getGpIconUrl(this.generalPartnerType)
        this.purchasedRate = this.purchased / this.listPrice * 100

        const {
          membershipPaid,
          acceptedConfidentiality,
          membershipType,
          accreditedInvestorStatus,
          acknowledgedInvestmentDisclaimer,
          governmentIdStatus,
          investorOnVeriffWatchlist
        } = result.data.investor
        this.membershipPaid = membershipPaid
        this.membershipType = membershipType
        this.accreditedInvestorStatus = accreditedInvestorStatus
        this.confidentialDocSigned = acceptedConfidentiality
        this.acknowledgedInvestmentDisclaimer = acknowledgedInvestmentDisclaimer
        this.governmentIdStatus = governmentIdStatus
        this.investorOnVeriffWatchlist = investorOnVeriffWatchlist

        const { confidential, disclosed } = result.data.dueDiligence
        this.confidentialFiles = confidential
        this.disclosedFiles = disclosed

        this.pageviewTracker()
      })
    },
    getWealthDataOfAmerican() {
      if (!this.isAmerican) return
      getWealthInfo().then((result) => {
        if (!result.data) return
        const { annualIncome, netWorth } = result.data
        this.annualIncome = annualIncome
        this.netWorth = netWorth
      })
    },
    checkIdVerificationFlow() {
      getFeatureToggle('veriff_integration').then((result) => {
        if (result.success) this.useVeriff = result.data.enabled
      })
    },
    pageviewTracker() {
      if (this.saleStatus === 'sale') this.mixpanel.track('78zjjr68')
    },
    checkAvailableRegions() {
      verifyRegion(this.propertyId).then((data) => {
        this.investorInAvailableRegions = data.success
      })
    },
    navBack() {
      this.$router.go(-1)
    },
    toDashboard() {
      this.$router.push('/dashboard')
    },
    toReferralDashboard() {
      updateInvestorFlags({
        referral_gift_icon_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
        referral_page_last_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
      })
      this.$router.push('/referralDashboard')
    },
    goNext() {
      this.mixpanel.track('yjfawiai')
      if (!this.isAmerican && !this.acknowledgedInvestmentDisclaimer) this.showDisclaimer = true
      else this.nextStepRedirection()
    },
    // 1. check region; 2. check ID status
    nextStepRedirection() {
      if (!this.investorInAvailableRegions) {
        return this.$router.push({
          name: 'regionNotAvailable',
          query: { title: 'Invest in a property' },
        })
      }
      // pass this value down to the residency page
      localStorage.setItem('availableCountryForInvestment', this.country)
      // if (!this.membershipPaid) this.purchaseMembership()
      // else this.$router.push(this.nextStepUrl)

      if (!this.membershipPaid && this.nonMemberWaitPeriodActive) this.showEarlyAccessModal = true
      else if (this.governmentIdStatus === 'verified') this.$router.push(this.nextStepUrl)
      else this.showIdVerificationModal = true
    },
    getTypeIconUrl(type) {
      switch (type) {
      case 'Value-Add':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-value-add.svg'
      case 'Opportunistic':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-opportunistic.svg'
      case 'Core':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-core.svg'
      case 'Core Plus':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-core-plus.svg'
      }
    },
    getGpIconUrl(type) {
      switch (type) {
      case 'Coming Up':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/gp-coming-up.svg'
      case 'Established':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/gp-established.svg'
      case 'OG':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/gp-og.svg'
      }
    },
    async viewOM(type) {
      const w = window.open('')
      const response = await fetchDocUrl({
        model_name: 'Corporation',
        hashid: this.corporationId,
        document_type: type,
      })
      if (!response.success || !response.data.url) return w.close()
      w.location.href = response.data.url
    },
    getTimeBasedText(purchasedRate) {
      let text = ''
      const totalHoursRemaining = 24 * 15 * (1 - purchasedRate / 100)
      let days = Math.floor(totalHoursRemaining / 24)
      let hours = Math.ceil(totalHoursRemaining % 24)
      if (hours === 24) {
        days++
        hours = 0
      }
      if (!days) {
        text = hours + (hours > 1 ? ' hours' : ' hour')
      } else {
        if (hours) text = days + (days > 1 ? ' days ' : ' day ') + hours + (hours > 1 ? ' hours' : ' hour')
        else text = days + (days > 1 ? ' days' : ' day')
      }
      return text
    },
    purchaseMembership() {
      this.$router.push(`/membership/options?nextStepUrl=${encodeURIComponent(this.nextStepUrl)}`)
    },
    async viewFile(modelName, id, documentType) {
      const w = window.open('')
      const response = await fetchDocUrl({
        model_name: modelName,
        hashid: id,
        document_type: documentType,
      })
      if (!response.success || response.data.url === null) return w.close()
      w.location.href = response.data.url
    },
    viewConfidentialFile(modelName, id, documentType) {
      if (!this.confidentialDocSigned) return
      this.viewFile(modelName, id, documentType)
    },
    signConfidentialityAgreement() {
      this.$router.push(`/investorProfile?returnAfterSign=${encodeURIComponent(window.location.href)}`)
    },
    closeDisclaimerModal() {
      updateInvestorFlags({
        investment_disclaimer_acknowledged_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
      }).then(() => {
        this.showDisclaimer = false
        this.acknowledgedInvestmentDisclaimer = true
        this.nextStepRedirection()
      })
    },
    toMembershipSelection() {
      this.$router.push(`/membership/options?returnUrl=${encodeURIComponent(this.$route.path)}`)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

@keyframes bounce {
  7%   {top: 8px;}
  0%, 14%, 100%  {top: 15px;}
}

.property-purchase {
  &.is-mobile {
    padding: 68px 0;
    .fixed-button {
      position: fixed;
    }
  }
  .fixed-header {
    height: 68px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 12px rgba(45, 41, 38, 0.05);
    .nav-back {
      position: absolute;
      left: 33px;
    }
    .logo {
      width: 63px;
    }
    .referral-icon {
      position: absolute;
      right: 25px;
      top: 15px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #F0EEF8;
      &.bouncing {
        animation: bounce 4.6s ease-in-out infinite;
      }
      > img {
        width: 16px;
      }
      .notice-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #3CDBC0;
        position: absolute;
        top: -7.5px;
        right: -15px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .main-body {
    padding: 16px 24px;
    .nickname {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 24px;
    }
    .purple-box {
      background-color: #F0EEF8;
      border-radius: 12px;
      padding: 18px 16px 24px;
      .status {
        font-size: 20px;
      }
      .mini-prop {
        width: 120px;
        margin-top: 34px;
        margin-bottom: 8px;
      }
      .buy-widget {
        margin-bottom: 27px;
        .dynamic-text {
          font-size: 16px;
          color: #9185AE;
          line-height: 19px;
          &.has-mt-32 {
            margin-top: 32px;
          }
          &.has-mt-18 {
            margin-top: 18px;
          }
          &.has-mb-8 {
            margin-bottom: 8px;
          }
          .bold {
            color: #867aa5;
          }
        }
        .progress-bar {
          background-color: #FDFDFD;
          border-radius: 6px;
          width: 264px;
          height: 21px;
          padding: 3px;
          margin: 0 auto;
          .inner-bar {
            border-radius: 4px;
            height: 100%;
          }
        }
      }
      .om-button {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 16px;
        width: 240px;
        height: 43px;
        border-radius: 12px;
        margin: 0 auto;
        img {
          margin-right: 6px;
        }
      }
      .box-title {
        font-size: 16px;
      }
      .term,
      .distribution {
        height: 97px;
        border-radius: 12px;
        padding-top: 18px;
        margin-top: 8px;
        .has-text-blue {
          margin-top: 5px;
          font-size: 32px;
          line-height: 38px;
        }
      }
      .term {
        margin-top: 8px;
      }
      .metrics, .scores {
        margin-top: 8px;
        .has-background-white {
          width: calc(50% - 4px);
          height: 97px;
          border-radius: 12px;
          padding-top: 20px;
          .has-text-blue {
            margin-top: 5px;
            font-size: 32px;
            line-height: 38px;
            &.currency-container {
              width: 100%;
              .currency {
                font-size: 12px;
                position: absolute;
                right: 4px;
                top: -6px;
                line-height: 12px;
              }
            }
            .symbol {
              font-size: 20px;
              line-height: 32px;
              vertical-align: text-top;
            }
          }
        }
      }
    }
    .more-info {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.03em;
      padding: 24px 16px 0;
      .description,
      .plan {
        margin-top: 20px;
      }
      .section-title {
        font-size: 24px;
        line-height: 29px;
      }
      .gp {
        margin-top: 32px;
        .gp-type-icon {
          height: 49px;
          margin: 15px auto 11px;
          background-color: #F0EEF8;
          border-radius: 12px;
          padding: 0 12px;
          span {
            margin-left: 14px;
          }
        }
      }
      .investment-type {
        margin-top: 40px;
        .type-of-investment {
          height: 40px;
          background-color: #F0EEF8;
          border-radius: 12px;
          margin: 18px auto 12px;
          padding: 0 12px;
          span {
            margin-left: 6px;
          }
        }
      }
      .highlights {
        margin-top: 42px;
        .highlights-item {
          margin-top: 18px;
          .dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #3CDBC0;
            margin-top: 7px;
          }
          .text {
            flex: 1;
            padding-left: 18px;
          }
        }
      }
      .due-diligence-section {
        .due-diligence {
          margin-top: 32px;
        }
        .doc-section-title {
          line-height: 24px;
          margin-bottom: 8px;
        }
        .title-icon {
          margin-right: 6px;
        }
        .file-wrap {
          height: 56px;
          color: #9185AE;
          background-color: #F0EEF8;
          border-radius: 8px;
          padding: 0 18px;
          margin-bottom: 8px;
          .doc-icon {
            width: 14px;
          }
          .doc-info {
            height: 100%;
            flex: 1;
            padding: 0 8px 0 16px;
            .name {
              max-width: 200px;
              font-size: 14px;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .doc-size,
            .doc-size span {
              font-size: 12px;
              color: #9185AE;
            }
          }
        }
        .confidential-due-diligence {
          margin-top: 32px;
          .sign-confidential-doc {
            .button {
              border-radius: 12px;
              height: 43px;
              img {
                margin-right: 10px;
              }
            }
            .sign-hint {
              font-size: 12px;
              line-height: 14px;
              padding: 6px 0 20px;
            }
          }
        }
      }
      .disclaimer-section {
        margin-top: 32px;
        font-size: 12px;
        ::v-deep .main-text {
          line-height: 12px;
        }
        ::v-deep .statements {
          margin-top: 12px !important;
        }
        ::v-deep .footer-wrap {
          padding: unset;
        }
      }
    }
  }
  .fixed-button {
    position: absolute;
    width: 100%;
    bottom: 16px;
    left: 0;
    .button {
      width: 200px;
      height: 42px;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
      line-height: 42px;
      span {
        font-size: 24px;
        line-height: 42px;
      }
      &[disabled] {
        opacity: 1;
        background-color: #e8c18e;
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .property-purchase {
    padding-bottom: 60px;
    // TODO: add scrolling event and animation to change the sticking position
    .fixed-button {
      bottom: 120px;
    }
  }
}
</style>
