/**
 * Account verification flow
 * @param {Boolean} useVeriff true if the Flipper toggle (veriff_integration) is on
 * @param {Object} _this Vue instance calling this helper method
 * @param {String} returnUrl
 * @return {Promise}
 */
export const doAccountVerification = (useVeriff = true, _this, returnUrl) => {
  let route = useVeriff ? '/investorProfile/kyc' : '/investorProfile/governmentId/upload'

  if (returnUrl) route = `${route}?returnUrl=${encodeURIComponent(returnUrl)}`

  return _this.$router.push(route)
}
